import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";
import {jwtDecode} from 'jwt-decode'

export const user_login = createAsyncThunk(
    'auth/user_login',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/user-login',info, {withCredentials: true})
            // Check the current site URL and set cookies and localStorage accordingly
            localStorage.setItem('accessToken',data.token)
            return fulfillWithValue(data);
        } catch (error){
            // console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const seller_login = createAsyncThunk(
    'auth/seller_login',
    async (info,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.post('/seller-login',info, {withCredentials: true})
            localStorage.setItem('accessToken',data.token)
            return fulfillWithValue(data);
        } catch (error){
            // console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const get_user_info = createAsyncThunk(
    'auth/get_user_info',
    async (_ ,{rejectWithValue,fulfillWithValue}) => {
        try {
            const {data} = await api.get(`/get-user`,{withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            return rejectWithValue(error.response.data);
        }
    }
)

export const seller_register = createAsyncThunk(
    'auth/seller_register',
    async (info,{rejectWithValue,fulfillWithValue}) => {
       
        try {
            console.log(info);
            const {data} = await api.post('/seller-register',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            // console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const profile_image_upload = createAsyncThunk(
    'auth/profile_image_upload',
    async (info,{rejectWithValue,fulfillWithValue}) => {
       
        try {
            console.log(info);
            const {data} = await api.post('/profile-image-upload',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            // console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)

export const update_seller = createAsyncThunk(
    'auth/update_seller',
    async (info,{rejectWithValue,fulfillWithValue}) => {
       
        try {
            console.log(info);
            const {data} = await api.post('/update-seller',info, {withCredentials: true})
            return fulfillWithValue(data);
        } catch (error){
            // console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)



const returnRole = (token) => {
    if (token) {
        const decodeToken = jwtDecode(token);
        const expireTime =  new Date(decodeToken.exp * 1000)
        if (new Date() > expireTime) {
            localStorage.removeItem('accessToken')
            return ''
        } else {
            return decodeToken.role
        }
        
    } else {
        console.log('token not define');
    }
}

export const authReducer = createSlice({
    name: 'auth',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        userInfo: '',
        role: returnRole(localStorage.getItem('accessToken')),
        token: localStorage.getItem('accessToken'),
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(user_login.pending, (state, { payload }) => {
            state.loader = true
            state.userInfo = ''
        }).addCase(user_login.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(user_login.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
            state.token = payload.token 
            state.role = returnRole(payload.token) 
        })

        

        .addCase(seller_register.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(seller_register.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(seller_register.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message
            state.token = payload.token 
            state.role = returnRole(payload.token) 
        })

        .addCase(seller_login.pending, (state, { payload }) => {
            state.loader = true 
             state.userInfo = ''
        }).addCase(seller_login.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(seller_login.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
            state.token = payload.token 
            state.role = returnRole(payload.token) 
        })
        
        .addCase(get_user_info.fulfilled, (state, { payload }) => {
            state.loader = false
            state.userInfo = payload.userInfo
            state.token = payload.userInfo.id
        })

        .addCase(profile_image_upload.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(profile_image_upload.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(profile_image_upload.fulfilled, (state, { payload }) => {
            state.loader = false
            state.successMessage = payload.message 
            state.userInfo = payload.userInfo
        })


        .addCase(update_seller.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(update_seller.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(update_seller.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.successMessage = payload.message 
            state.userInfo = payload.userInfo
        })
    }
})

export default authReducer.reducer

export const {messageClear} = authReducer.actions